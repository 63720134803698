<template>
  <div class="exam-root-1">
    <div class="exam-top">
      <img
        src="../../assets/arrow_circle_back.png"
        style="cursor: pointer; width: 36px; height: auto"
        @click="goBack"
      />
      <img
        src="../../assets/loginLogo.png"
        style="height: 30px; margin-left: 30px"
      />
    </div>
    <div class="exam-content">
      <div class="certContainer">
        <img
          src="../../assets/certFblc.jpg"
          class="model"
        />
        <!-- <img
          v-if="item.real_rank <= 30 && item.real_rank > 20"
          src="../../assets/cert_30.jpg"
          class="model"
        />
        <img
          v-if="item.real_rank <= 20 && item.real_rank > 10"
          src="../../assets/cert_20.jpg"
          class="model"
        />
        <img
          v-if="item.real_rank <= 10"
          src="../../assets/cert_10.jpg"
          class="model"
        /> -->
        <div class="ziZm">兹证明</div>

        <div class="event">{{ item.project_name }}</div>

        <div class="projectName">{{ item.application_event }}</div>

        <div class="award">{{ item.award_show_lable }}</div>

        <div class="awardPh">{{'('+ item.percent_lable + ')'}}</div>

        <div class="student">
        <span class="name">{{ student.student_lastName + student.student_givenName }}</span>
        </div>
        <div class="school">{{ account.account_name }}</div>

        <div class="email">{{ student.student_email }}</div>

        <div class="rhZm">荣获</div>

        <img class="saiquImg" v-if="item.group_name == '92赛区'" src="../../assets/fblc-92.png">
        <img class="saiquImg" v-if="item.group_name == 'FE赛区'" src="../../assets/fblc-FE.png">
        <img class="saiquImg" v-if="item.group_name == '标准赛区'" src="../../assets/fblc-标准.png">
        <img class="saiquImg" v-if="item.group_name == 'BFA赛区'" src="../../assets/fblc-BFA.png">
        <img class="saiquImg" v-if="item.group_name == '国际赛区'" src="../../assets/fblc-国际.png">
        <img class="saiquImg" v-if="item.group_name == '职教赛区'" src="../../assets/fblc-职教.png">


        <div class="tefaLab">特发此证，以资鼓励。</div>

        <div class="timeLab">{{'颁发日期：' + award_create_time}}</div>
        <!-- <div class="timeLab">颁发日期：</div> -->


      </div>
    </div>
  </div>
</template>

<script>
import { findMyTranscript } from "../../api/index";
import {formatDate} from '../../utils/date'
import { getUserId, getProjectCode } from "../../utils/store";
import "../../assets/common/font.css";

export default {
  data() {
    return {
      item: {},
      student: {},
      account: {},
      award_create_time:'2022年6月7日',
      score_id: this.$route.query.score_id,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      findMyTranscript(getUserId(), getProjectCode()).then((res) => {
        let score = res.data.data.score_info.find(
          (x) => x._id.$id === this.score_id
        );
       
        var time = JSON.parse(score.create_time) * 1000
        var temp_date = new Date(time)
        // this.award_create_time = formatDate(temp_date, 'yyyy年MM月dd日');
        this.item = score.award_info.award;
        this.student = res.data.data.student_info;
        this.account = res.data.data.account_info;
      });
    },
    handleEventName(event) {
      if (event == undefined) {
        return "";
      }
      let event_array = event.split(" ");
      console.log(111, event, event_array);
      return event_array[0];
    },

    goBack() {
      this.$router.go(-1);
    },
    upfirstName(name) {
      let transferName = name.toLowerCase();
      return transferName.charAt(0).toUpperCase() + transferName.slice(1);
    },
    rank(number) {
      if (number === 1) {
        return "st";
      } else if (number === 2) {
        return "nd";
      } else if (number === 3) {
        return "rd";
      } else {
        return "th";
      }
    },
    printPersonal() {
      let url = this.$router.resolve({
        path: "/blank",
      });
      let newWindow = window.open(url.href, "_blank"); //打印窗口要换成页面的url
      let obj = document.getElementById("cerP");

      let docStr = obj.innerHTML;

      newWindow.document.write(docStr);

      newWindow.document.close();

      newWindow.print();

      newWindow.close();
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "aleo-bold";
  src: url("../../assets/fonts/aleo-bold.ttf");
}
@font-face {
  font-family: "Aleo-Bold-2";
  src: url("../../assets/fonts/Aleo-Bold-2.otf");
}
@font-face {
  font-family: "Aleo-Italic-4";
  src: url("../../assets/fonts/Aleo-Italic-4.otf");
}
@font-face {
  font-family: "Aleo-Light-5";
  src: url("../../assets/fonts/Aleo-Light-5.otf");
}
@font-face {
  font-family: "Aleo-Regular-7";
  src: url("../../assets/fonts/Aleo-Regular-7.otf");
}
.el-dialog {
  width: 320mm;
}
.exam-content {
  width: 100%;
  flex: 1;
  background-image: url("../../assets/exam-content-bg.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  padding: 30px 9vw;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.awardTitle {
  font-weight: bold;
  text-align: center;
  color: #0f429e;
  font-size: 60px;
  margin-top: 30px;
  font-family: Montserrat-SemiBold;
}
.awardTitle1 {
  font-weight: bold;
  text-align: left;
  color: #0036a0;
  font-size: 30px;
  margin-top: 60px;
  margin-left: 80px;
  font-family: Montserrat-SemiBold;
}
.awardLine {
  background-color: #00359f;
  margin-left: 80px;
  margin-right: 80px;
  height: 1px;
  margin-top: 10px;
  font-family: Montserrat-SemiBold;
}
.scoreLab1 {
  margin-top: 60px;
  margin-right: 10px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  font-size: 30px;
  color: #fd5900;
  float: right;
  font-family: Montserrat-SemiBold;
}

.scoreLab2 {
  margin-top: 65px;
  margin-right: 80px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  color: #0f429e;
  font-size: 25px;
  float: right;
  font-family: Montserrat-SemiBold;
}
.awardSubTitle {
  text-align: left;
  color: #2d50b0;
  font-size: 15px;
  margin-top: 20px;
  margin-left: 80px;
  font-family: Montserrat-SemiBold;
}
.returnStyle {
  position: absolute;
  // display: flex;
  // margin-bottom: 10px;
  // align-items: center;
  bottom: 40px;
  margin-left: 45%;
  font-weight: 600;
  width: 140px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  // align-content: center;
  // align-items: center;
  border-radius: 15px;
  background-color: #2d50b0;
  color: white;
  font-family: Montserrat-SemiBold;
}
.box {
  display: flex;
  justify-content: left;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 20px;
  // height: 250px;
  // background-color: #FD5900;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: left;
  align-content: left;
  text-align: left;
  float: left;
}
.child {
  // width: 50px;
  // background-color: aqua;
  height: 30px;
  padding-left: 10px;
}
.infoStyle {
  display: flex;
  border: 1px solid #2d50b0;
  border-radius: 16px;
  color: #2d50b0;
  margin-left: 20px;
  margin-top: -25px;
  font-family: Montserrat-SemiBold;
}
.infoImg {
  width: 14px;
  height: 14px;
}
.exam-top {
  height: 60px;
  background: #00348b;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 9vw;
}

.exam-root-1 {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.certContainer {
  position: relative;
  color: rgb(42, 70, 154);
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 430px;
    left: 116px;
    font-weight: 500;
  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 525px;
    left: 250px;
    font-weight: 500;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }
   
  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 300px;
    left: 116px;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    .name {
      margin-right: 40px;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 260px;
    left: 116px;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    background-color:  red;
  }
}
</style>
